<template>
    <b-container>
        <b-form id="saveKeywords" @submit.prevent="saveKeywords()">
            <b-card class="d-flex">
                <!-- Header Section -->
                <b-row class="border rounded mx-0 mb-2 p-2 justify-content-center">
                    <b-col cols="12">
                        <h1 class="mb-0">New Keyword Import</h1>
                    </b-col>
                </b-row>

                <!-- Form Section -->
                <b-row class="border rounded p-2 mx-0">
                    <!-- Account Selection -->
                    <b-col cols="12" lg="4" md="12" sm="12">
                        <label for="Account">Account</label>
                        <b-form-group class="mb-0">
                            <v-select placeholder="Select Account Name" id="h-account-name" v-model="accountName"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="accountNames.filter(item => item.value !== 'All')"
                                @change="filterApplication" />
                        </b-form-group>
                        <small v-if="errors.accountName" class="text-danger">
                            {{ errors.accountName }}
                        </small>
                    </b-col>

                    <!-- Date Period Selection -->
                    <b-col cols="12" lg="8" md="12" sm="12">
                        <label for="Period">Period</label>
                        <b-form-group class="mb-0">
                            <b-row>
                                <b-col cols="12" lg="6" md="6" sm="12">
                                    <b-form-datepicker v-model="startPeriod" placeholder="Start Period"/>
                                </b-col>
                                <b-col cols="12" lg="6" md="6" sm="12">
                                    <b-form-datepicker v-model="endPeriod" placeholder="End Period" class="data_picker_end"/>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <small v-if="errors.period" class="text-danger">
                            {{ errors.period }}
                        </small>
                    </b-col>

                    <b-col cols="12" class="px-0 mt-2">
                        <div class="drop-zone" @click="openFileExplorer">
                            <span class="drop-zone__prompt">Drop file here or click to upload</span>
                            <input type="file" ref="fileInput" accept=".zip" class="drop-zone__input"
                                style="display: none" @change="handleFileChange" />
                        </div>
                        <small v-if="errors.file" class="d-block mb-1 text-danger text-center">
                            {{ errors.file }}
                        </small>
                    </b-col>

                    <b-col cols="12" class="mt-1 d-flex justify-content-center">
                        <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-success" v-b-tooltip.hover
                            title="Save" type="submit" class="px-4">
                            Save
                            <feather-icon icon="SaveIcon" size="14" />
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>
        </b-form>
        <b-card>
            <div v-if="!isLoading && !noData">
                <b-row>
                    <b-col cols="12" lg="6" md="6">
                        <label>Filter Account By Name</label>
                        <v-select :clearable="false" placeholder="type account name ..." id="h-account-name"
                            class="mb-2" v-model="filterByAccountName" :options="filterByAccountNames" />
                    </b-col>
                    <b-col cols="12" lg="6" md="6">
                        <label>Filter Account By Name</label>
                        <v-select class="mb-2" placeholder="select period" id="h-pacakge-name" :clearable="false"
                            v-model="period" :options="periods" />
                    </b-col>
                </b-row>
                <b-table striped hover responsive :items="items" :fields="fields" :per-page="perPage"
                    :current-page="currentPage">
                    <template #cell(actions)="data">
                        <b-button-group>
                            <b-button v-if="data.item.import_history_ID" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="flat-danger" class="btn-icon rounded-circle" v-b-tooltip.hover title="Delete"
                                @click="confirmdeleteImport(data.item.import_history_ID)">
                                <feather-icon icon="Trash2Icon" size="20" />
                            </b-button>
                        </b-button-group>
                    </template>
                    <template #cell(period)="data">
                        <p v-if="data.item.created_at_formated">
                            {{ data.item.start_period + " To " + data.item.end_period }}
                        </p>
                    </template>
                    <template #cell(timestamp)="data">
                        <p v-if="data.item.created_at_formated">
                            {{ data.item.created_at_formated }}
                        </p>
                    </template>
                    <template #cell(Imported)="data">
                        <span class="d-block text-center" v-if="data.item.created_at_formated"><b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-success"
                                class="btn-icon rounded-circle" v-b-tooltip.hover title="Imported">
                                <feather-icon icon="CheckCircleIcon" size="20" /> </b-button></span>
                        <span class="d-block text-center" v-else><b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="flat-warning" class="btn-icon rounded-circle" v-b-tooltip.hover
                                title="Not Imported">
                                <feather-icon icon="AlertTriangleIcon" size="20" /> </b-button></span>
                    </template>
                </b-table>
                <b-row>
                    <b-col cols="12" lg="6" md="6" sm="6">
                        <b-form-group class="mb-0">
                            <label class="d-inline-block text-sm-left mr-50">Per page</label>
                            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                            class="w-25" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6" md="6" sm="6">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="end"
                        size="sm" class="my-0" />
                    </b-col>
                </b-row>
            </div>
            <div style="height: 300px;" v-else-if="isLoading && !noData"
                class="d-flex justify-content-center align-items-center">
                <b-spinner variant="primary" style="width: 4rem; height: 4rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else-if="!isLoading && noData">
                No Data Available
            </div>
        </b-card>
        <b-modal id="modal-deleteing-app" ok-variant="danger" ok-title="Confirm" modal-class="modal-deleteing-app"
            centered title="Deleting App!" @ok="deleteImport">
            <b-card-text>Are you sure you want to delete this app?</b-card-text>
        </b-modal>
    </b-container>
</template>

<script>
import {
    BRow,
    BCol,
    BModal,
    BCard,
    BFormSelect,
    BTable,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BForm,
    BInputGroupAppend,
    BCardText,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    VBHover,
    BContainer,
    BFormInvalidFeedback,
    BAspect,
    BFormDatepicker,
    BSpinner
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import vSelect from "vue-select";
import axios from "@axios";
import router from "@/router";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        BRow,
        BCol,
        BFormTextarea,
        BCard,
        BContainer,
        BFormInput,
        BFormGroup,
        BFormFile,
        BImg,
        BFormSelect,
        BInputGroupAppend,
        BForm,
        BTable,
        BCardText,
        BInputGroup,
        BModal,
        BButtonGroup,
        VBTooltip,
        BPagination,
        BButton,
        vSelect,
        BAspect,
        BFormInvalidFeedback,
        BFormDatepicker,
        BSpinner
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-hover": VBHover,
        Ripple,
    },
    data() {
        return {
            accountName: null,
            accountNames: [],
            filterByAccountNames: [],
            startPeriod: null,
            endPeriod: null,
            isSend: false,
            packageNames: [],
            selectedFile: null,
            errors: {},
            fileError: "",
            isLoading: true,
            noData: false,
            periods: [],
            period: null,
            items: [],
            originItems: [],
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 100],
            filterByAccountName: { label: "All", value: "All" },
            fields: [
                {
                    key: "title",
                    label: "Application",
                },
                {
                    key: "account_name",
                    label: "Account",
                },
                {
                    key: "period",
                    label: "period",
                },
                {
                    key: "created_at_formated",
                    label: "Imported at",
                },
                "Imported",
                "Actions",
            ],
        };
    },
    computed: {
        totalRows() {
            return this.keywords.length;
        }
    },
    created() {
        this.showAllAccounts();
        this.getHistory();
    },
    mounted() {
        this.setupDropZone();
    },
    watch: {
        filterByAccountName(newValue) {
            if (newValue.label === "All" && this.period === "All") {
                this.items = this.originItems;
            } else if (newValue.label !== "All" && this.period !== "All") {
                this.items = this.filterByAccAndPeriod(this.originItems, newValue.label, this.period);
            } else if (newValue.label !== "All" && this.period === "All") {
                this.items = this.filterByAccName(this.originItems, newValue.label);
            } else if (newValue.label === "All" && this.period !== "All") {
                this.items = this.filterByPeriods(this.originItems, this.period);
            }
        },
        period(newValue) {
            if (this.filterByAccountName.label === "All" && newValue === "All") {
                this.items = this.originItems;
            } else if (this.filterByAccountName.label !== "All" && newValue !== "All") {
                this.items = this.filterByAccAndPeriod(this.originItems, this.filterByAccountName.label, newValue);
            } else if (this.filterByAccountName.label !== "All" && newValue === "All") {
                this.items = this.filterByAccName(this.originItems, this.filterByAccountName.label);
            } else if (this.filterByAccountName.label === "All" && newValue !== "All") {
                this.items = this.filterByPeriods(this.originItems, newValue);
            }
        }
    },
    methods: {
        async getPeriods() {
            await axios.get(`/get-imports-periods`)
                .then(({ data }) => {
                    this.periods = [
                        "All",
                        ...data.periods.map((p) => {
                            return `${p.start} To ${p.end}`;
                        })
                    ];

                    if (this.periods.length > 0) {
                        this.period = this.periods[this.periods.length - 1];
                        this.filterByPeriods();
                    } else {
                        this.items = this.originItems
                    }
                })
                .catch(console.error);
        },
        filterByPeriods(data, period) {
            return data?.filter(item => item.formatedPeriod == period) || [];
        },
        filterByAccName(data, accountName) {
            return data?.filter(item => item.account_name == accountName) || [];
        },
        filterByAccAndPeriod(data, accountName, period) {
            return data?.filter(item => item.account_name == accountName && item.formatedPeriod == period) || [];
        },
        async showAllAccounts() {
            try {
                const response = await axios.post("/get-accounts", {
                    user_token: JSON.parse(localStorage.getItem("accessToken")),
                });

                if (response.data.status == "Unauthorized") {
                    this.handleUnauthorized();
                    return;
                }

                this.accountNames = [
                    { label: "All", value: "All" },
                    ...response.data.accounts.map((el) => ({
                        label: el.account_name,
                        value: el.id,
                    }))
                ];

                this.filterByAccountNames = [...this.accountNames];
            } catch (error) {
                console.error("Failed to fetch accounts:", error);
                this.$toast.error("Failed to load accounts");
            }
        },
        async filterApplication(accountId) {
            try {
                const response = await axios.post(`/get-apps-by-account`, {
                    accountId,
                    user_token: JSON.parse(localStorage.getItem("accessToken")),
                });

                if (response.data.status == "Unauthorized") {
                    this.handleUnauthorized();
                    return;
                }

                if (response.data.status === "success") {
                    this.packageNames = response.data.apps?.map((el) => ({
                        label: el.title,
                        value: el.id,
                    })) || [];
                }
            } catch (error) {
                console.error("Failed to filter applications:", error);
                this.$toast.error("Failed to load applications");
            }
        },
        openFileExplorer() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            this.fileError = "";

            if (!file) {
                this.fileError = "Please select a file.";
                return;
            }

            if (!file.name.endsWith(".zip")) {
                this.fileError = "Only .zip files are allowed.";
                return;
            }

            this.selectedFile = file;
            this.updateThumbnail(this.$el.querySelector(".drop-zone"), file);
        },
        handleUnauthorized() {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
        },
        validateForm() {
            this.errors = {};

            if (!this.accountName) {
                this.errors.accountName = "Account selection is required.";
            }

            if (!this.startPeriod || !this.endPeriod) {
                this.errors.period = "Both start and end periods must be selected.";
            }

            if (!this.selectedFile) {
                this.errors.file = "A .zip file is required.";
            }

            return Object.keys(this.errors).length === 0;
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: { title, icon, text, variant },
            });
        },
        async saveKeywords() {
            if (!this.validateForm()) {
                return;
            }

            const formData = new FormData();
            formData.append("accountName", this.accountName.label);
            formData.append("accountId", this.accountName.value);
            formData.append("startPeriod", this.startPeriod);
            formData.append("endPeriod", this.endPeriod);
            formData.append("file", this.selectedFile);

            try {
                const response = await axios.post("/new-store-keywords", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (response.data.status === "Unauthorized") {
                    handleUnauthorized();
                } else if (response.data.status === "success") {
                    this.showToast(
                        "success",
                        "CheckCircleIcon",
                        "Success",
                        "Keywords saved successfully!"
                    );
                    this.accountName = null;
                    this.startPeriod = null;
                    this.endPeriod = null;
                    this.selectedFile = null;
                    const thumbElement = document.querySelector('div.drop-zone__thumb');
                    if (thumbElement) {
                        thumbElement.remove();
                    }
                    document.querySelector('div.drop-zone').insertAdjacentHTML(
                        'afterbegin',
                        '<span class="drop-zone__prompt">Drop file here or click to upload</span>'
                    );
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    this.showToast(
                        "danger",
                        "AlertTriangleIcon",
                        "Error",
                        response.data.message
                    );
                }
            } catch (error) {
                console.error(error);
                this.showToast(
                    "danger",
                    "AlertTriangleIcon",
                    "Error",
                    "An unexpected error occurred. Please try again."
                );
            }
        },
        setupDropZone() {
            const dropZone = this.$el.querySelector(".drop-zone");
            const fileInput = this.$refs.fileInput;

            dropZone.addEventListener("dragover", (e) => {
                e.preventDefault();
                dropZone.classList.add("drop-zone--over");
            });

            ["dragleave", "dragend"].forEach((type) => {
                dropZone.addEventListener(type, () => {
                    dropZone.classList.remove("drop-zone--over");
                });
            });

            dropZone.addEventListener("drop", (e) => {
                e.preventDefault();
                dropZone.classList.remove("drop-zone--over");

                if (e.dataTransfer.files.length) {
                    fileInput.files = e.dataTransfer.files;
                    this.handleFileChange({ target: fileInput });
                }
            });
        },
        updateThumbnail(dropZoneElement, file) {
            let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");

            // Remove the prompt if it exists
            const promptElement = dropZoneElement.querySelector(".drop-zone__prompt");
            if (promptElement) {
                promptElement.remove();
            }

            // Create thumbnail element if it doesn't exist
            if (!thumbnailElement) {
                thumbnailElement = document.createElement("div");
                thumbnailElement.classList.add("drop-zone__thumb");
                dropZoneElement.appendChild(thumbnailElement);
            }

            thumbnailElement.dataset.label = file.name;

            // For ZIP files, we'll just show the filename
            thumbnailElement.style.backgroundImage = null;
        },
        async getHistory() {
            this.isLoading = true;
            this.noData = false;
            await axios.post("/get-import-history", {
                user_token: JSON.parse(localStorage.getItem("accessToken")),
            })
                .then(async (response) => {
                    if (response.data.status == "Unauthorized") {
                        handleUnauthorized();
                        this.isLoading = false;
                        this.noData = true;
                    }
                    if (response.data.status === "success") {
                        if (response.data.count > 0) {
                            this.originItems = response.data.histories.map((item) => {
                                let timestamp;
                                if (item.created_at_formated) {
                                    const [datePart, timePart] = item.created_at_formated.split(" ");
                                    const [day, month, year] = datePart.split("/");
                                    timestamp = new Date(`${year}-${month}-${day}T${timePart}`).getTime();
                                } else {
                                    timestamp = 0;
                                }
                                const formatedPeriod = item.start_period + " To " + item.end_period;
                                return { ...item, formatedPeriod, timestamp };
                            });
                            await this.getPeriods();
                            this.totalRows = this.items.length;
                            this.isLoading = false;
                            this.noData = false;
                        } else {
                            this.items = [];
                            this.totalRows = 0;
                            this.isLoading = false;
                            this.noData = true;
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        confirmdeleteImport(id) {
            this._id = id;
            this.$root.$emit("bv::show::modal", "modal-deleteing-app");
        },
        deleteImport() {
            axios.post("/delete-import-history", {
                import_id: this._id,
            })
                .then(({ data }) => {
                    if (data.status == "Unauthorized") {
                        removeLocalStorageItems();
                        router.push({ name: "auth-login" });
                    } else if (data.status == "success") {
                        this.showToast("success", "TrashIcon", "Done", data.message);
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    } else if (data.status == "failed") {
                        this.showToast(
                            "danger",
                            "AlertTriangleIcon",
                            "Error",
                            "Something went wrong!"
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.drop-zone {
    width: 95%;
    margin: auto;
    height: 100px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    color: #cccccc;
    border: 2px dashed #404656;
    border-radius: 10px;

    &--over {
        border-style: solid;
    }

    &__input {
        display: none;
    }

    &__prompt {
        user-select: none;
    }

    &__thumb {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        background-color: #cccccc;
        background-size: cover;
        position: relative;

        &::after {
            content: attr(data-label);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 5px 0;
            color: #ffffff;
            background: #161d31;
            font-size: 14px;
            text-align: center;
        }
    }
}

.vs__selected {
    user-select: none;
}

.account-error,
.application-error {
    bottom: -18px;
    left: 0;
}

@media (max-width: 767px) {
    .data_picker_end {
        margin-top: 20px;
    }
}
</style>
